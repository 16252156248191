<template>
  <div class="avtivityheader">
    <div class="activity_name">
      <p>活动名称：{{pcActiveName}}</p>
    </div>
    <!-- <div class="qrcode">
      <el-link type="primary" :underline="false">{{actUrl}}</el-link>
      <i class="el-icon-copy-document ml10" @click="copy(actUrl)"></i>
      <el-popover placement="bottom" width="200" trigger="hover">
        <div class="scan_con">
          <el-image :src="qrcodePoster"></el-image>
        </div>
        <i slot="reference" class="el-icon-bank-card ml10"></i>
      </el-popover>
    </div> -->
  </div>
</template>

<script>
import { copyToClipboard } from "@/assets/utils.js";

export default {
  name: "avtivityheader",
  data() {
    return {
      activityID: 0,
      actName: "", // 活动名称
      actUrl: "", // 活动地址
      qrcodePoster: "", // 活动二维码
      qrCodeDialogVisible: false,
      showHeader: false,
      pcActiveName:'',
    };
  },
  methods: {
    toActList() {
      this.$router.push("/config/activitylist");
    },
    copy(data) {
      copyToClipboard(data);
      this.$message.success("复制成功");
    }
  },
  created() {
    this.pcActiveName = localStorage.getItem('pcActiveName');
    this.$bus.$on("activityID", id => {
      this.activityID = id;
    });

    this.$bus.$on("actName", name => {
      this.actName = name;
    });

    this.$bus.$on("actUrl", src => {
      this.actUrl = src;
    });

    this.$bus.$on("qrcodePoster", src => {
      this.qrcodePoster = src;
    });
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.avtivityheader {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: #fff;
  height: 80px;
  padding: 0 20px;
  // border-bottom: 1px solid #f1f1f1;
  .activity_name {
    font-size: 20px;
  }
  .qrcode {
    i {
      font-size: 20px;
      cursor: pointer;
    }
  }
}
</style>
